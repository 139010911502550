import React from "react";
import styled from "styled-components";
import PositionContainer from "../shared/container";
import MainTitle from "../shared/heroTitles";
import Paragraph from "../shared/paragraphs";
import { Link } from "gatsby";
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 30px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 540px;

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const SubtitleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 499px;

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const RulesBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 792px;
  gap: 40px;
  margin-bottom: 90px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const TermsTitles = styled.div`
  color: #111111;
  font-size: 20px;
  line-height: 27px;
  font-weight: bold;
  font-style: normal;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #111111;
  margin: 0px;
  padding: 0px;
`;

function Hero() {
  return (
    <Container>
      <PositionContainer>
        <ContentHolder>
          <TitleBox>
            <MainTitle features={true}>Terms and Conditions</MainTitle>
          </TitleBox>
          <SubtitleContent>
            <Title terms={true}>PDFREADER.COM TERMS OF WEBSITE USE</Title>
            <Paragraph type="center">Last update: October 4, 2022</Paragraph>
          </SubtitleContent>
          <RulesBox>
            <Box>
              <Paragraph>
                These terms and conditions (hereinafter collectively referred to
                as the “ToS”) apply to the use of the website Pdfreader.com
                (hereinafter referred to as the “Site”), whether as a guest or a
                registered user (hereinafter referred to as “You” or “Your”).
                The Site is operated by PDF Pro Software Ltd., with registered
                office at 1383 W 8th Ave, Vancouver, BC V6H 3V9, Canada,
                Business Registration no. 700468291BC0001, including its
                subsidiaries, affiliates and contractors acting on its behalf
                (hereinafter referred to as “Us”, “We”, or “Our”)
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>IMPORTANT NOTICE:</TermsTitles>
              <Paragraph>
                Your continued use of the Site constitutes Your expressed
                acceptance of and agreement to be bound by these ToS, as in
                force from time to time. Therefore, You understand and
                acknowledge that by accessing or using the Site You agree to be
                bound by these ToS. If You do not accept such ToS in their
                entirety, You must not access or use the Site.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>SECT. 1</TermsTitles>
              <Paragraph>
                Access to the Site. Access to the Site is permitted on a
                temporary basis, and We will not be liable if for any reason the
                Site is unavailable at any time or for any period.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>SECT. 2</TermsTitles>
              <Paragraph>
                Software downloads. The use of any software downloads made
                available from the Site will be subject to the terms and
                conditions of the end user licence agreement (EULA) associated
                with such software (available here). By downloading, installing,
                modifying, copying or by otherwise using such software You
                express Your full acceptance of such EULA.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>SECT. 3</TermsTitles>
              <Paragraph>
                External links. Where our Site contains links to other websites
                and resources provided by third parties, these links are
                provided for Your information only. If You use these links, You
                will leave the Site. We have not reviewed these third party
                websites and We have no control over the contents thereof, and
                accept no responsibility for them or for any loss or damage that
                may arise from Your use of them. We do not endorse or make any
                warranties or representations about the other websites, or any
                information, software or other products or materials found
                there, or any results that may be obtained from using them. If
                You decide to access any of the third party websites linked to
                Our Site, You do this entirely at Your own risk.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>SECT. 4</TermsTitles>
              <Paragraph>
                Your responsibilities. You may use the Site only for lawful
                purposes. In particular, You may not use our Site: (i) in any
                way that breaches any applicable law or regulations; (ii) in any
                way that is unlawful or fraudulent, or has unlawful or
                fraudulent purpose or effect; (iii) for the purpose of harming
                or attempting to harm any individual in any way; (iv) to create
                liability for us in any way; (v) in any way that causes us to
                lose (in whole or in part) the services of our ISPs or other
                suppliers; and (vi) to knowingly transmit any data, send or
                upload any material that contains viruses, Trojan horses, worms,
                time-bombs, keystroke loggers, spyware, adware or any other
                harmful programs or similar computer code designed to adversely
                affect the operation of any computer software or hardware. We
                may report any breach under this Section to the relevant law
                enforcement authorities and We will co-operate with those
                authorities by disclosing Your identity to them, if technically
                feasible.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>SECT. 5</TermsTitles>
              <Paragraph>
                Intellectual Property Rights. We hold and retain all an any
                title and interest in and to the Site and its content, as well
                as in any intellectual property right associated therewith. We
                grant You a limited, personal, non-transferable,
                non-sublicensable, revocable license to access and use the Site
                only in the manner presented and expressly permitted by Us.
                Except for this limited license, nothing in these ToS will be
                interpreted as a transfer, in whole or in part, of any right in
                the Site to You or any other third party. In particular, without
                limiting the foregoing, none of the content and/or information
                in the Site may be reverse-engineered, modified, reproduced,
                republished, translated into any language or computer language,
                re-transmitted in any form or by any means, resold or
                redistributed without Our prior written consent.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>SECT. 6</TermsTitles>
              <Paragraph>
                Privacy and data protection. Our Privacy Policy (
                <Link to="/privacy/">available here</Link>) and Cookie Policy (
                <Link to="/cookies/">available here</Link>) govern the use of
                information collected from, or provided by, You at the Site. By
                using the Site, You consent to such processing and You warrant
                that all data provided by You is lawfull, accurate and complete.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>SECT. 7</TermsTitles>
              <Paragraph>
                DISCLAIMER OF WARRANTY. THE SITE AND ITS CONTENT ARE PROVIDED
                “AS IS” AND “WITH ALL FAULTS”, WITHOUT WARRANTY OF ANY KIND. TO
                THE MAXIMUM EXTENT PERMITTED BY THE APPLICABLE LAW, WE DISCLAIM
                ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING
                WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
                SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, OR
                NON-INFRINGEMENT. TO THE FULLEST EXTENT PERMITTED OR AUTHORIZED
                BY LAW AND WITHOUT LIMITATION OF THE FOREGOING, WE DO NOT
                WARRANT THAT THE SITE WILL MEET YOUR REQUIREMENTS OR THAT IT
                WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>SECT. 8</TermsTitles>
              <Paragraph>
                DISCLAIMER OF LIABILITY. TO THE MAXIMUM EXTENT PERMITTED BY THE
                APPLICABLE LAW AND EXCEPTION MADE FOR DEATH OR PERSONAL INJURY
                CAUSED BY NEGLIGENCE, FRAUDULENT MISREPRESENTATION, OR ANY OTHER
                LIABILITY TO THE EXTENT THAT SUCH LIABILITY CANNOT BE EXCLUDED
                OR LIMITED BY THE APPLICABLE LAW, IN NO EVENT SHALL WE BE LIABLE
                FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR
                CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO,
                PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA OR
                PROFITS, BUSINESS INTERRUPTION, ETC.) HOWEVER CAUSED AND ON ANY
                THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR
                TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT
                OF THE USE OF THE SITE OR ITS CONTENT, EVEN IF WE ARE AWARE OF
                OR HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>SECT. 9</TermsTitles>
              <Paragraph>
                Governing law and jurisdiction. These ToS are governed by the
                substantive and procedural Law of British Columbia, without
                regard to conflict of law provisions. Any dispute arising out
                of, or relating to, these ToS shall be submitted to the
                exclusive jurisdiction of the British Columbia Court(s),
                although we retain the right to bring proceedings against You
                for breach of these ToS in Your country of residence or any
                other relevant country.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>SECT. 10</TermsTitles>
              <Paragraph>
                Severability. Should any part of these ToS be declared illegal
                or unenforceable, the remaining portion will remain in full
                force and effects.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>SECT. 11</TermsTitles>
              <Paragraph>
                Amendments. We may, in our sole discretion, amend these ToS at
                any time by posting a revised version thereof on the Site at .
                Your use of the Site following changes to the ToS after the
                effective date of a revised version thereof constitutes Your
                expressed acceptance of and agreement to be bound by the ToS as
                in force from time to time.
              </Paragraph>
            </Box>
          </RulesBox>
        </ContentHolder>
      </PositionContainer>
    </Container>
  );
}

export default Hero;
