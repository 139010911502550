import React from "react";
import Seo from "../components/shared/seo";
import Hero from "../components/termsPage/hero";

function Terms() {
  return (
    <>
      <Seo
        title="Terms & Conditions - PDFReader.com"
        noRobotsIndex={true}
        special={true}
      />
      <Hero />
    </>
  );
}

export default Terms;
